import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Fingerprint from '@material-ui/icons/Fingerprint';
import SecurityIcon from '@material-ui/icons/Security';
import DevicesIcon from '@material-ui/icons/Devices';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import VpnLockIcon from '@material-ui/icons/VpnLock';
// core components
import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import InfoArea from 'components/InfoArea/InfoArea';

import AnyConnectIcon from 'components/AnyConnectIcon';

import productStyle from 'assets/jss/material-kit-react/views/landingPageSections/productStyle';

const useStyle = makeStyles(productStyle);

const ProductSection = () => {
  const classes = useStyle();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            <FormattedMessage
              id="landing.product.title"
              defaultMessage="Elastic VPN Cloud"
            />
          </h2>
          <h5 className={classes.description}>
            <FormattedMessage
              id="landing.product.desc"
              defaultMessage="Our public VPN service that can scale up/down based on workload."
            />
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title={
                <FormattedMessage
                  id="landing.product.info1.title"
                  defaultMessage="Powered by Cloud"
                />
              }
              description={
                <FormattedMessage
                  id="landing.product.info1.desc"
                  defaultMessage="Cloud-Powered backend enables us to access unlimidated servers/IP resources(virtually)"
                />
              }
              icon={CloudCircleIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title={
                <FormattedMessage
                  id="landing.product.info2.title"
                  defaultMessage="Passworldless Login"
                />
              }
              description={
                <FormattedMessage
                  id="landing.product.info2.desc"
                  defaultMessage="The only VPN service supports passwordless login with certificate authentiation"
                />
              }
              icon={Fingerprint}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title={
                <FormattedMessage
                  id="landing.product.info3.title"
                  defaultMessage="Modern VPN Technology"
                />
              }
              description={
                <FormattedMessage
                  id="landing.product.info3.desc"
                  defaultMessage="Comparing with IPSec, SSL VPN is easy for use and widerly adopted by Fortune 100 companies"
                />
              }
              icon={SecurityIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title={
                <FormattedMessage
                  id="landing.product.info4.title"
                  defaultMessage="Enterprise VPN Client"
                />
              }
              description={
                <FormattedMessage
                  id="landing.product.info4.desc"
                  defaultMessage="AnyConnect VPN Client is the de facto Enterprise VPN Client"
                />
              }
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title={
                <FormattedMessage
                  id="landing.product.info5.title"
                  defaultMessage="Global Access"
                />
              }
              description={
                <FormattedMessage
                  id="landing.product.info5.desc"
                  defaultMessage="One account enables you to access VPN service from anywhere in the world"
                />
              }
              icon={VpnLockIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <InfoArea
              title={
                <FormattedMessage
                  id="landing.product.info6.title"
                  defaultMessage="Protection for All Devices"
                />
              }
              description={
                <FormattedMessage
                  id="landing.product.info6.desc"
                  defaultMessage="AnyConnect Client supports Windows, Mac OS, Linux, iOS and Andrios"
                />
              }
              icon={DevicesIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default ProductSection;
