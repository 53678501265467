import React, { useEffect, useState } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
// core components
import FormHelperText from '@material-ui/core/FormHelperText';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import PrintIcon from '@material-ui/icons/Print';
import * as yup from 'yup';
import { Formik, ErrorMessage } from 'formik';

import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import CustomInput from 'components/CustomInput/CustomInput';
import { sendMessage } from '@allconnect/store/lib/user/user.actions';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectStatus } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import UserActionTypes from '@allconnect/store/lib/user/user.types';

import Button from 'components/CustomButtons';

import workStyle from 'assets/jss/material-kit-react/views/landingPageSections/workStyle';

const useStyle = makeStyles(workStyle);

function ContactForm({ sendMessage, appStatus, reset }) {
  const classes = useStyle();
  const [state, setState] = useState('init');

  useEffect(() => {
    if (appStatus === UserActionTypes.SEND_MESSAGE_SUCESS) {
      setState('completed');
      setTimeout(() => reset(), 2000);
    }
  }, [appStatus]);

  const initValues = {
    email: '',
    name: '',
    message: '',
  };

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid email')
      .required('E-mail is required'),
    message: yup.string().required('Message is required'),
    name: yup.string().required('Name is required'),
  });

  const onSend = values => {
    const { email, name, message } = values;
    console.log(`sending message`, name, email, message);
    sendMessage({ name, email, message });
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center" style={{ padding: 0, margin: 0 }}>
        <GridItem cs={12} sm={12} md={10}>
          <div>
            <h2 className={classes.title}>
              <FormattedMessage
                id="contact.title"
                defaultMessage="Get In Touch"
              />
            </h2>
            <h4 className={classes.description}>
              <FormattedMessage
                id="contact.subtitle"
                defaultMessage="Send us your message or give us a call"
              />
            </h4>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Formik
                initialValues={initValues}
                validationSchema={formSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  onSend(values);
                  resetForm(initValues);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form>
                    <CustomInput
                      labelText={
                        <FormattedMessage
                          id="contact.name"
                          defaultMessage="Name"
                        />
                      }
                      id="name"
                      error={touched.name && errors.name ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'name',
                        name: 'name',
                        onChange: handleChange,
                        onBlur: handleBlur,
                        value: values.name,
                      }}
                    />
                    <FormHelperText>
                      <ErrorMessage name="name" />
                    </FormHelperText>
                    <CustomInput
                      labelText={
                        <FormattedMessage
                          id="contact.email"
                          defaultMessage="Email"
                        />
                      }
                      id="email"
                      error={touched.email && errors.email ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'email',
                        name: 'email',
                        onChange: handleChange,
                        onBlur: handleBlur,
                        value: values.email,
                      }}
                    />
                    <FormHelperText>
                      <ErrorMessage name="email" />
                    </FormHelperText>
                    <CustomInput
                      labelText={
                        <FormattedMessage
                          id="contact.message"
                          defaultMessage="Message"
                        />
                      }
                      id="message"
                      error={touched.message && errors.message ? true : false}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 2,
                        type: 'message',
                        name: 'message',
                        onChange: handleChange,
                        onBlur: handleBlur,
                        value: values.message,
                      }}
                    />
                    <FormHelperText>
                      <ErrorMessage name="message" />
                    </FormHelperText>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        round
                        color="primary"
                        onClick={e => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                        disabled={isSubmitting}
                        endIcon={
                          appStatus === UserActionTypes.SEND_MESSAGE_SUCESS ? (
                            <CheckIcon />
                          ) : (
                            <SendIcon />
                          )
                        }
                      >
                        <FormattedMessage
                          id="contact.send"
                          defaultMessage="Send"
                        />
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="contact.address"
                        defaultMessage="Address"
                      />
                    }
                    secondary="1201 North Market Street Suite 111, Wilmington, DE 19801"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="contact.phone"
                        defaultMessage="Phone"
                      />
                    }
                    secondary="+1(302) 391-6038"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PrintIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage id="contact.fax" defaultMessage="Fax" />
                    }
                    secondary="+1(302) 391-6039"
                  />
                </ListItem>
              </List>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  appStatus: selectStatus,
});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(clearError()),
  sendMessage: ({
    email,
    name,
    message,
  }: {
    name: string;
    email: string;
    message: string;
  }) => dispatch(sendMessage({ email, name, message })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
