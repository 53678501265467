import { title } from 'assets/jss/material-kit-react.jsx';

const workStyle = theme => {
  const {
    palette: { type, text, background, common },
  } = theme;
  return {
    section: {
      padding: '30px 0',
    },
    title: {
      ...title,
      color: text.primary,
      marginBottom: '50px',
      marginTop: '30px',
      minHeight: '32px',
      textDecoration: 'none',
      textAlign: 'center',
    },
    description: {
      color: text.primary,
      textAlign: 'center',
    },
    textCenter: {
      textAlign: 'center',
    },
    textArea: {
      marginRight: '15px',
      marginLeft: '15px',
      color: text.primary,
    },
  };
};

export default workStyle;
