import React, { useEffect } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'gatsby-plugin-intl';

import { navigate } from 'gatsby-plugin-intl';
// core components

import { Parallax } from '@jackywxd/shared-material-ui';
import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
// Sections for this page
import ProductSection from './Sections/ProductSection';
import GetStarted from './Sections/GetStarted';
import ServerLocation from '../Servers/Sections/ServerLocation';
import ContactForm from './Sections/WorkSection';

import Button from 'components/CustomButtons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import { selectLang } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles(landingPageStyle);

function LandingPage({ currentUser, currentLang }) {
  const classes = useStyles();
  const lgScreen = useMediaQuery('(min-width:500px)');

  // useEffect(() => {
  //   if (currentLang && window && window.$chatwoot) {
  //     console.log(`changing title`, currentLang);
  //     if (currentLang === 'en') window.$chatwoot.setLocale('en');
  //     else window.$chatwoot.setLocale('zh');
  //     window.chatwootSettings = {
  //       type: 'expanded_bubble',
  //       launcherTitle: currentLang === 'en' ? 'Chat with us' : '在線聯繫',
  //     };
  //   }
  // }, [currentLang]);
  return (
    <Layout>
      <Parallax filter image={require('assets/img/ssl-bg.jpg')}>
        <div className={classes.container}>
          <h1 className={classes.title} style={{ color: '#fff' }}>
            <FormattedMessage
              id="landing.title"
              defaultMessage="The New Era of Cloud VPN"
            />
          </h1>
          <h2 style={{ color: '#fff' }}>
            <FormattedMessage
              id="landing.subtitle"
              defaultMessage="FREE Enterprise Grade SSL VPN for Everyone! Now in Public Beta."
            />
          </h2>
          <br />
          <Button
            size="lg"
            round
            color="google"
            onClick={() => {
              if (currentUser) {
                open('https://heidi.cloud/hi-vpn');
              } else {
                open('https://heidi.cloud/hi-vpn');
              }
            }}
          >
            {currentUser ? (
              <FormattedMessage
                id="button.myAccount"
                defaultMessage="My Account"
              />
            ) : (
              <FormattedMessage
                id="button.registerFreeAccount"
                defaultMessage="Purchase Now"
              />
            )}
          </Button>
          {/* <div style={{ fontSize: '0.9rem' }}>30-Day Money-Back Guarantee</div> */}
        </div>
      </Parallax>
      <div
        className={
          lgScreen ? classNames(classes.main, classes.mainRaised) : classes.main
        }
      >
        <div className={lgScreen ? classes.container : undefined}>
          <ProductSection />
          <ServerLocation />
          <ContactForm />
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentLang: selectLang,
});

export default connect(mapStateToProps, null)(LandingPage);
