import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
} from 'assets/jss/material-kit-react.jsx';

const infoStyle = theme => {
  const {
    palette: { type, text, background, common },
  } = theme;
  return {
    infoArea: {
      maxWidth: '360px',
      margin: '0 auto',
      padding: '0px',
    },
    iconWrapper: {
      float: 'left',
      marginTop: '24px',
      marginRight: '10px',
    },
    primary: {
      color: primaryColor,
    },
    warning: {
      color: warningColor,
    },
    danger: {
      color: dangerColor,
    },
    success: {
      color: successColor,
    },
    info: {
      color: infoColor,
    },
    rose: {
      color: roseColor,
    },
    gray: {
      color: grayColor,
    },
    icon: {
      width: '36px',
      height: '36px',
    },
    descriptionWrapper: {
      color: text.primary,
      overflow: 'hidden',
    },
    title: {
      ...title,
      color: text.primary,
    },
    description: {
      color: text.secondary,
      overflow: 'hidden',
      marginTop: '0px',
      fontSize: '14px',
    },
    iconWrapperVertical: {
      float: 'none',
    },
    iconVertical: {
      width: '61px',
      height: '61px',
    },
  };
};

export default infoStyle;
